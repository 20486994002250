// React
import React from "react"

// Site components
import Layout from "../components/layout"
import SEO from "../components/seo"
import LinkExt from "../components/linkext"

// Styles
import "../styles/pages/GiftsPage.scss"

// List of product tags
const tags = {
    giftcard: 'Gift Card',
    bluetooth: 'Bluetooth',
    wireless: 'Wireless',
    speakers: 'Speakers',
    gamepad: 'Gamepad',
    //
    sega: "SEGA",
    snes: "SNES",
    nes: "NES",
    nintendo: "Nintendo",
    playstation: "PlayStation",
    xbox: "Xbox",
};

// List of products
const products = [

    // Gamepads
    {
        title: "8Bitdo | Sn30 Pro+ Bluetooth Gamepad (Sn Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07T8JKVNT&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07T8JKVNT/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07T8JKVNT&linkCode=as2&tag=gamedevmix-20&linkId=c715f6b43a8e5a5803a9eb767a9508b4",
        tags: [
            tags.gamepad,
            tags.playstation,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Sn30 Pro+ Bluetooth Gamepad (G Classic Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07TDTXHJP&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07TDTXHJP/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07TDTXHJP&linkCode=as2&tag=gamedevmix-20&linkId=55aed03d7cbbfb8f603d469bfe7fdfd7",
        tags: [
            tags.gamepad,
            tags.playstation,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Sn30 Pro+ Bluetooth Gamepad (Black Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B01ND166P6&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B01ND166P6/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B01ND166P6&linkCode=as2&tag=gamedevmix-20&linkId=17b28ece1146e04377b6edb24409c603",
        tags: [
            tags.gamepad,
            tags.playstation,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | SF30 Pro Controller",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0748S3GXG&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0748S3GXG/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0748S3GXG&linkCode=as2&tag=gamedevmix-20&linkId=bbb55ea5d2bcccbe4faa85680a22ae5a",
        tags: [
            tags.gamepad,
            tags.snes,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | SF30 Pro Wireless Bluetooth Controller Gamepad Dual Classic Joystick, with Extra Carrying Bag",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0781NZ2BB&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0781NZ2BB/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0781NZ2BB&linkCode=as2&tag=gamedevmix-20&linkId=20b4d27e67359704a3e5c4f730179d99",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Sn30 Pro Bluetooth Gamepad (Sn Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GK8J3V8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GK8J3V8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GK8J3V8&linkCode=as2&tag=gamedevmix-20&linkId=67e697d30b4007cbfdbb226b17153ab8",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | SN30 Pro Controller",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0748S1VDC&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0748S1VDC/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0748S1VDC&linkCode=as2&tag=gamedevmix-20&linkId=a034009084de335179fa3e6c7c5a3b14",
        tags: [
            tags.gamepad,
            tags.snes
        ],
    },
    {
        title: "8Bitdo | SN30 Pro Wireless Bluetooth Controller with Classic Joystick Gamepad",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B078125QQ8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B078125QQ8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B078125QQ8&linkCode=as2&tag=gamedevmix-20&linkId=7b99937b6a2da713b674a493e9c18f2c",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | SN30 Pro Wireless Bluetooth Controller Gamepad Dual Classic Joystick for Windows, Mac OS, Android, Linux, Raspberry Pi, Steam, etc, Compatible with Nintendo Switch, with Extra Carrying Bag",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0781LDL1J&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0781LDL1J/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0781LDL1J&linkCode=as2&tag=gamedevmix-20&linkId=88ea84d685f213623471c15b421b41c2",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Sn30 Pro Bluetooth Gamepad (G Classic Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GKLG3ZP&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GKLG3ZP/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GKLG3ZP&linkCode=as2&tag=gamedevmix-20&linkId=a6c9545e2cca5e8752516eeee183ceca",
        tags: [
            tags.gamepad,
            tags.snes
        ],
    },
    {
        title: "8Bitdo | SF30 2.4G Wireless Controller for SFC Classic Edition",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B074HBQ78V&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B074HBQ78V/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B074HBQ78V&linkCode=as2&tag=gamedevmix-20&linkId=192db01f03bd1e5c2bc59ce58a2cff9d",
        tags: [
            tags.gamepad,
            tags.snes
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad for Nintendo Switch, Windows, macOS, Android, Raspberry Pi (Sn Edition) - Nintendo Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GK8LJBJ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GK8LJBJ/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GK8LJBJ&linkCode=as2&tag=gamedevmix-20&linkId=45de2436c0a306e832c6d7e9f172e0fc",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad for Nintendo Switch, Windows, macOS, Android, Raspberry Pi (G Classic Edition) - Nintendo Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0734MTKWQ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0734MTKWQ/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0734MTKWQ&linkCode=as2&tag=gamedevmix-20&linkId=e63a007c00f68f03d14b8872ac089f77",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | SN30 Retro Set (Transparent Edition) - Super NES",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B075WSH5KT&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B075WSH5KT/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B075WSH5KT&linkCode=as2&tag=gamedevmix-20&linkId=eb544d8ba441c114e1b814a4c084cb3a",
        tags: [
            tags.gamepad,
        ],
    },

    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad (Yellow)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GKCGCZ2&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GKCGCZ2/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GKCGCZ2&linkCode=as2&tag=gamedevmix-20&linkId=4ee0f1b27e3493572e7eaca0ff814180",
        tags: [
            tags.gamepad,
            tags.snes,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad (Red)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GK3HSW5&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GK3HSW5/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GK3HSW5&linkCode=as2&tag=gamedevmix-20&linkId=435c3eb7dc07d8e528698ffcbcd56802",
        tags: [
            tags.gamepad,
            tags.snes,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad (Green)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GKGGHP1&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GKGGHP1/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GKGGHP1&linkCode=as2&tag=gamedevmix-20&linkId=a05ef521cab8eb280ebf8c49fc02d682",
        tags: [
            tags.gamepad,
            tags.snes,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad (Blue)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GKPXZRW&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07GKPXZRW/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GKPXZRW&linkCode=as2&tag=gamedevmix-20&linkId=772b7e8c6af6e92c93d9ef0c5571c809",
        tags: [
            tags.gamepad,
            tags.snes,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | SN30 Pro USB Gamepad (SN Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07R481CVY&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07R481CVY/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07R481CVY&linkCode=as2&tag=gamedevmix-20&linkId=e9f763507ee61fcc6cc2872aff744af3",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | SN30 2.4G Wireless Controller for SNES Classic Edition [nintendo_super_NES]",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B074HBNNH6&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B074HBNNH6/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B074HBNNH6&linkCode=as2&tag=gamedevmix-20&linkId=3a0c5fd9fd2c12c83ecf6255b695d3db",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | N30 Pro2 Bluetooth Gamepad (N Edition) - Nintendo Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07HDVZP84&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07HDVZP84/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07HDVZP84&linkCode=as2&tag=gamedevmix-20&linkId=04b52c8ab3e3e8cb3ee7682118d9cdba",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | SN30 Retro Set (Black Edition) - Super NES",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B075WRBK7Y&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B075WRBK7Y/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B075WRBK7Y&linkCode=as2&tag=gamedevmix-20&linkId=841e4f81e563cfff4112ce87daa81376",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Sn30 2.4G Wireless Gamepad for Original SNES/Sfc (Sn Edition) - Super NES",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B08536VCZL&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B08536VCZL/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08536VCZL&linkCode=as2&tag=gamedevmix-20&linkId=b8881e02e53d019977cf5fc5cdeedfd5",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Sn30 Bluetooth Gamepad (G Classic Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0734MTKWQ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0734MTKWQ/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0734MTKWQ&linkCode=as2&tag=gamedevmix-20&linkId=c0e999cf2ad4783c7a34b090719a2c68",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | M30 Bluetooth Gamepad (Sega Genesis / Mega Drive Style)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07MSN1NPC&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07MSN1NPC/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07MSN1NPC&linkCode=as2&tag=gamedevmix-20&linkId=645ab73e5c3e8360eeb848335d6fe26a",
        tags: [
            tags.gamepad,
            tags.sega
        ],
    },
    {
        title: "8Bitdo | M30 2.4G Wireless Gamepad for the Original Sega Genesis and Sega Mega Drive - Sega Genesis,Black",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07HB1XFQW&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07HB1XFQW/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07HB1XFQW&linkCode=as2&tag=gamedevmix-20&linkId=51e2c2e0c73359c41b4b21bb3ecad4ab",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | N30 2.4G Wireless Gamepad for NES Classic Edition",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B078W7SVP2&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B078W7SVP2/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B078W7SVP2&linkCode=as2&tag=gamedevmix-20&linkId=0af01314685ff95e9fda6cdff5c7a7da",
        tags: [
            tags.gamepad,
            tags.nes
        ],
    },
    {
        title: "8Bitdo | N30 Bluetooth Gamepad for Switch Online Game Support Turbo and Home [Nintendo Switch Online] - Nintendo Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07L9Z33MH&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07L9Z33MH/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07L9Z33MH&linkCode=as2&tag=gamedevmix-20&linkId=3b33914cad756428ea99b3cf78063d12",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo N30 Pro2 Wireless Bluetooth Gamepad Controller N Edition - Updated 2019 Version - Includes Carrying Case",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07B3RWW1F&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07B3RWW1F/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07B3RWW1F&linkCode=as2&tag=gamedevmix-20&linkId=8eef55eccb00059604ea57d2644d6594",
        tags: [ tags.gamepad ],
    },
    {
        title: "8Bitdo Zero 2 Bluetooth Gamepad（Yellow Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B081HNH39K&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B081HNH39K/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B081HNH39K&linkCode=as2&tag=gamedevmix-20&linkId=658eabd7b15f709ecf28a38ec5454910",
        tags: [ tags.gamepad ],
    },
    {
        title: "8Bitdo | Zero 2 Bluetooth Gamepad（Turquoise Edition）",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B081HML6MP&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B081HML6MP/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B081HML6MP&linkCode=as2&tag=gamedevmix-20&linkId=f72f2a6bbbfc1ecb880a4e484c486086",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo N30 Pro 2 (C Edition) Wireless Controller Dual Classic Bluetooth Video Game Joystick Gamepad",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07L9TQZ7X&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07L9TQZ7X/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07L9TQZ7X&linkCode=as2&tag=gamedevmix-20&linkId=53ecd322b36fe6f368f1edba765da6f8",
        tags: [ tags.gamepad ],
    },
    {
        title: "8Bitdo | PCE Core 2.4G Wireless Gamepad for PC Engine Mini, PC Engine CoreGrafx Mini, TurboGrafx-16 Mini (PCE Core Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0872RB7K9&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0872RB7K9/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0872RB7K9&linkCode=as2&tag=gamedevmix-20&linkId=1da7d72ae856082ae03fb26b36e47c49",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | N30 Pro 2 Bluetooth Gamepad (M Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07HDTLFR9&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07HDTLFR9/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07HDTLFR9&linkCode=as2&tag=gamedevmix-20&linkId=c8217d6756b9ca9d644853c9d412449b",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | PCE 2.4G Wireless Gamepad for PC Engine Mini, PC Engine CoreGrafx Mini, TurboGrafx-16 Mini (PCE Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0872R3ZYF&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0872R3ZYF/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0872R3ZYF&linkCode=as2&tag=gamedevmix-20&linkId=0ce8058361df771faa5526b5c3c6f7f2",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | TG16 2.4G Wireless Gamepad for PC Engine Mini, PC Engine CoreGrafx Mini, TurboGrafx-16 Mini (TG16 Edition)",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B0872QNPHK&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B0872QNPHK/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B0872QNPHK&linkCode=as2&tag=gamedevmix-20&linkId=3b101d16690f2be3dabef7bc1113f720",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Twin Cube Stereo Bluetooth Speakers",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B073ZNVXMK&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B073ZNVXMK/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B073ZNVXMK&linkCode=as2&tag=gamedevmix-20&linkId=70f391398fc1ca40d7d61c28a4e20ab5",
        tags: [
            tags.gamepad,
            tags.speakers,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | Retro Cube Bluetooth Speaker",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B019O0JMOQ&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B019O0JMOQ/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B019O0JMOQ&linkCode=as2&tag=gamedevmix-20&linkId=93c9ab7d1cfe60c1debc09e25385925e",
        tags: [
            tags.gamepad,
            tags.speakers,
            tags.bluetooth,
        ],
    },
    {
        title: "8Bitdo | N30 Pro 2 (M Edition) Wireless Controller Dual Classic Bluetooth Video Game Joystick Gamepad for Android/PC/Mac OS/Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07L9WLH1M&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07L9WLH1M/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07L9WLH1M&linkCode=as2&tag=gamedevmix-20&linkId=eb405c42a9fba69ca1325075476c311a",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "8Bitdo | Zero 2 Bluetooth Gamepad（Yellow Edition） - Nintendo Switch",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B081HNH39K&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B081HNH39K/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B081HNH39K&linkCode=as2&tag=gamedevmix-20&linkId=f0a00f728af009e0f0bc2e93b76d9c5e",
        tags: [
            tags.gamepad,
        ],
    },
    {
        title: "Switch Controller Wireless, Switch Pro Controller Retro 6-Axis Joypad Joystick Controller Gamepad for Nintendo NES SNES Mini Classic Edition 2018",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07H887RP8&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=zeldaxlove64c-20",
        linkUrl: "https://www.amazon.com/gp/product/B07H887RP8/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07H887RP8&linkCode=as2&tag=zeldaxlove64c-20&linkId=1f4ad04501c60b505703d928891b3cd3",
        tags: [
            tags.gamepad,
        ],
    },

    // {
    //     title: "",
    //     imgUrl: "",
    //     linkUrl: "",
    //     tags: [
    //         tags.gamepad,
    //     ],
    // },


    // Gift Cards
    {
        title: "Xbox Gift Card",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07KRWJCQW&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07KRWJCQW/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07KRWJCQW&linkCode=as2&tag=gamedevmix-20&linkId=5ab88e4041e3d9a7f5bfe40fd889a0d5",
        tags: [
            tags.giftcard,
            tags.xbox
        ],
    },
    {
        title: "PlayStation Store Gift Card",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07YGX2878&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07YGX2878/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07YGX2878&linkCode=as2&tag=gamedevmix-20&linkId=3d07ead8d2cbe2f2072e8a7b345421bf",
        tags: [
            tags.giftcard,
            tags.playstation,
        ],
    },
    {
        title: "Nintendo eShop Gift Card",
        imgUrl: "//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07ZJ6RY1W&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL250_&tag=gamedevmix-20",
        linkUrl: "https://www.amazon.com/gp/product/B07ZJ6RY1W/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07ZJ6RY1W&linkCode=as2&tag=gamedevmix-20&linkId=52a911177b8   ef57b51e5b94748f94f95",
        tags: [
            tags.giftcard,
            tags.nintendo,
        ],
    },
];

// Component for the gifts page
const GiftsPage = ({
    location
}) => {

    // Frontmatter for this page
    const pageTitle = "Gift Ideas";
    const pageCategory = "Game Development Products";
    const pageDescription = "Get something games-related for your loved ones. Or for yourself!";
    const pageDate = "2020-05-29";

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
            />

            {/* Page */}
            <article id="gifts-page">

                {/* Page header */}
                <header className="page-header">

                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>

                </header>

                {/* Page main */}
                <main className="page-main">

                    {/* Page body */}
                    <section className="page-body">

                        {/* Product list */}
                        <div className="list-products">
                            {products.map(product => (
                                <LinkExt
                                    key={product.title}
                                    to={product.linkUrl}
                                >
                                    <div className="product-thumbnail">
                                        <img
                                            border="0"
                                            src={product.imgUrl}
                                            alt={product.title}
                                        />
                                    </div>

                                    <div className="product-title">
                                        {product.title}
                                    </div>

                                    <div className="product-tags">
                                        {product.tags && product.tags.length > 0 && product.tags.map(tag => (
                                            <div key={tag}>
                                                {tag}
                                            </div>
                                        ))}
                                    </div>
                                </LinkExt>
                            ))}
                        </div>

                    </section>

                </main>

                {/* Page footer */}
                <footer className="page-footer">

                    <small>
                        * Some of the links on this page are affiliate links, meaning if you make a purchase, I get a small commission at no extra cost to you. Thank you so much for your support, it really is appreciated.
                    </small>

                    Last updated: {pageDate}

                </footer>

            </article>

        </Layout>
    )
}

// Export component
export default GiftsPage
